<template>
  <div class="meetings">
    <Meeting v-for="meeting in meetings" :key="meeting.id" :meeting="meeting" />

      <div class="about">
        <h1>Ostéopathe a domicile sur Beaugency</h1>
        <h2>Appelez moi : 06 72 20 XX XX</h2>
        <img src="../assets/coco.png" style="width: 250px; height: 500px">
      </div>

  </div>
</template>

<script>
// @ is an alias to /src a
import Meeting from "@/components/Meeting.vue";
import MettingService from "../services/MettingService";


export default {
  name: "MeetingList",
  components: {
    Meeting,
  },
  data() {
    return {
      meetings: null,
    };
  },
  created() {
    MettingService.getMeetings().then((response) => {
      this.meetings = response.data;
    });
  },
};
</script>

<style scoped>
.meetings {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
